<template>
    <section class="content">
        <div class="card card-primary">
            <div class="card-header">
                <h3 class="card-title">Form Data</h3>
            </div>
            <!-- /.card-header -->
            <!-- form start -->
            <form role="form" @submit="submitForm">
                <div class="card-body">
                    <p v-if="errors.length">
                        <b>Please correct the following error(s):</b>
                        <ul>
                            <li v-for="error in errors">{{ error }}</li>
                        </ul>
                    </p>
                    <div class="row">
                        <div class="col-md-6 form-group">
                            <label class="control-label">Skenario Jurnal</label>
                            <select v-model="form.skenario" class="form-control">
                                <option value="2">2 (Jurnal Pickup NonCOD)</option>
                                <option value="3">3 (Jurnal Retur NonCOD)</option>
                                <option value="4">4 (Jurnal Klaim RTK Disetujui)</option>
                                <option value="5">5 (Jurnal Klaim NonRTK Disetujui)</option>
                                <option value="6">6 (Jurnal Pickup COD)</option>
                                <option value="7">7 (Jurnal Rekon COD)</option>
                                <option value="9">9 (Jurnal Retur Diserahkan COD)</option>
                                <option value="10">10 (Jurnal Retur Diterima COD)</option>
                                <option value="11">11 (Jurnal Klaim RTK Disetujui COD)</option>
                                <option value="12">12 (Jurnal Klaim NonRTK Disetujui COD)</option>
                            </select>
                        </div>
                        <div class="col-md-6 form-group">
                            <label class="control-label">Kode</label>
                            <input type="text" class="form-control" v-model="form.id">
                        </div>
                        <div class="col-md-4 form-group">
                            <input id="status" v-model="form.reverse" type="checkbox"/>
                            <label for="status">Jurnal Balik</label>
                        </div>
                    </div>
                </div>
                    <!-- /.card-body -->
                    <div class="card-footer">
                        <button type="button" class="btn btn-secondary" v-on:click="submitForm">Proses</button>                    
                        </div>
            </form>
        </div>
    </section>
    <!-- /.content -->
</template>
<script>
import { authFetch } from '@/libs/hxcore';
import $ from 'jquery';
export default {
    name: 'Test',
    data() {
        return {
            errors: [],
            countries: [{label: 'Canada', code: 'ca'}, {label: 'Indonesia', code: 'id'}],
            form: {
                name: '',
                opt1:'',
                username: '',
                reverse: '',
                skenario: '',
            }
        }
    },
    components: {
    },
    mounted() {
        $('.loading-overlay').removeClass('show');
    },
    methods: {
        submitForm: function(e) {
            //if (this.form.name && this.form.username) {
                var data = Object.keys(this.form).map(key => encodeURIComponent(key) + '=' + encodeURIComponent(this.form[key])).join('&')
                console.log('masuk',data);
                authFetch('/examples/test', {
                        method: 'POST',
                        body: data
                    })
                    .then(res => {
                        if (res.status === 201) {
                            alert('OK');
                        } else if (res.status === 400) {
                            //let errorResponse = await res.json();
                            //this.errors.push(errorResponse.error);
                        }
                        return res.json();
                    })
                    .then(js => {
                        if(js.success == true){
                        alert("Proses Berhasil")
                        }else{
                            alert("Proses gagal")
                        }
                        // window.location.reload();
                    });
            //}
            this.errors = [];
            e.preventDefault();
        },
    }
}
</script>
<style>
.twitter-typeahead {
    display: block !important;
}
</style>